@import "~antd/dist/antd.css";

#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  margin: 16px;
}

.responsiveContent {
  margin: 24px 16px;
  padding: 24;
  background: #fff;
  min-height: 280px;
}

.responsiveNav {
  display: none !important;
}

@media (max-width: 700px) {
  .responsiveDrawer {
    display: none;
  }

  .responsiveHeader {
    display: none;
  }

  .responsiveNav {
    display: inherit !important;
  }

  .responsiveContent {
    margin: 70px 16px;
  }
}
