.login {
  height: 100%;
  width: 100%;
  background: url("../../assets/llanero1.jpg");
  background-size: cover;
  position: absolute;
}

.loginForm {
  position: relative;
  height: 500px;
  width: 300px;
  left: 10%;
  top: 15%;
  box-shadow: 2.5px 2.5px 15px black;
  padding: 25px;
  background: whitesmoke;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loginTitle {
  height: 150px;
  width: 200px;
  background: url("../../assets/llanerologo.svg") no-repeat;
  background-size: cover;
  margin: 20px auto;
}
